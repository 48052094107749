<template>
	<section class="container">
		<div class="row">
			<div class="col-auto mx-auto"></div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'home',
	data() {
		return {}
	},
	created() {
		this.$emit('updateLoader')
	},
}
</script>

<style lang="scss"></style>
